<template>
  <div>
    <div class="px-4 py-6 statsCard">
      <div class="md:flex justify-around">
        <div
          v-if="!suggestions.keywordStatus"
          class="my-auto"
        >
          <p>
            Keyword Suggestions are turned off <br />
            <span v-if="suggestions.allCampaignsSelected"> for all Campaigns. </span>
            <span v-else> on this Campaign. </span>
            <router-link to="/console/site/search/keyword-suggestions"
              >Please enable Keyword Suggestion first.</router-link
            >
          </p>
        </div>
        <div
          v-if="suggestions.keywordStatus"
          class="my-auto"
        >
          <p>
            This indicates how many <br />
            search terms have been <br />
            suggested by the AI in your <br />
            selected period.
          </p>
        </div>
        <div class="flex">
          <div class="my-auto">
            <IcStarCircleDisabled v-if="!suggestions.keywordStatus"></IcStarCircleDisabled>
            <IcStarCircleEnabled v-if="suggestions.keywordStatus"></IcStarCircleEnabled>
          </div>
          <div class="ml-2 my-auto">
            <div class="text-4xl leading-none text-bb-text-secondary pNumLarge">
              {{ suggestions.summary.total.toFixed(2) }}
            </div>
            <div class="text-bb-text-secondary">Total Suggestions</div>
          </div>
        </div>
        <div class="flex">
          <div class="my-auto">
            <IcSearchPositiveDisabled v-if="!suggestions.keywordStatus"></IcSearchPositiveDisabled>
            <IcSearchPositiveEnabled v-if="suggestions.keywordStatus"></IcSearchPositiveEnabled>
          </div>
          <div class="ml-2 my-auto">
            <div class="text-xl leading-none text-bb-text-secondary pNum">{{ suggestions.summary.positive }}</div>
            <div class="font-bold">Positive</div>
          </div>
        </div>
        <div class="flex">
          <div class="my-auto">
            <IcSearchNegativeDisabled v-if="!suggestions.keywordStatus"></IcSearchNegativeDisabled>
            <IcSearchNegativeEnabled v-if="suggestions.keywordStatus"></IcSearchNegativeEnabled>
          </div>
          <div class="ml-2 my-auto">
            <div class="text-xl leading-none text-bb-text-secondary pNum">{{ suggestions.summary.negative }}</div>
            <div class="font-bold">Negative</div>
          </div>
        </div>
        <div class="flex">
          <div class="my-auto">
            <IcSearchPauseDisabled v-if="!suggestions.keywordStatus"></IcSearchPauseDisabled>
            <IcSearchPauseEnabled v-if="suggestions.keywordStatus"></IcSearchPauseEnabled>
          </div>
          <div class="ml-2 my-auto">
            <div class="text-xl leading-none text-bb-text-secondary pNum">{{ suggestions.summary.pause }}</div>
            <div class="font-bold">Pause</div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full mt-10">
      <div class="text-center mx-auto justify-center items-center gap-2 flex">
        <div class="text-bb-brand-purple bg-bb-secondary-purple h-8 w-8 flex items-center justify-center rounded-full">
          <ic-list />
        </div>
        <div class="text-black text-xs font-medium">View list of all Keyword Suggestions</div>
        <router-link to="/console/site/search/keyword-suggestions">Go to list</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import IcStarCircleDisabled from '@/components/icon/ic-star-circle-disabled.vue'
import IcStarCircleEnabled from '@/components/icon/ic-star-circle-enabled.vue'
import IcSearchPositiveDisabled from '@/components/icon/ic-search-positive-disabled.vue'
import IcSearchNegativeDisabled from '@/components/icon/ic-search-negative-disabled.vue'
import IcSearchPositiveEnabled from '@/components/icon/ic-search-positive-enabled.vue'
import IcSearchNegativeEnabled from '@/components/icon/ic-search-negative-enabled.vue'
import IcSearchPauseEnabled from '@/components/icon/ic-search-pause-enabled.vue'
import IcSearchPauseDisabled from '@/components/icon/ic-search-pause-disabled.vue'
import IcList from '@/components/icon/ic-list.vue'

export default {
  name: 'KeywordSuggestionsCard',
  components: {
    IcStarCircleDisabled,
    IcStarCircleEnabled,
    IcSearchPositiveDisabled,
    IcSearchNegativeDisabled,
    IcSearchPositiveEnabled,
    IcSearchNegativeEnabled,
    IcSearchPauseDisabled,
    IcSearchPauseEnabled,
    IcList,
  },
  props: {
    suggestions: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.statsCard {
  box-shadow: 0px 12px 47px -2px #0000000d;
  border-radius: 16px;
}
</style>
